.btn{
    border: none;
    background: none;
}

.small{
    width: 140px;
    height: 50px;
    border-radius: 10px;
}

.full{
    background-color: var(--primaryColor);
    color: var(--white);
    font-weight: bold;
    font-size: 16px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.4);

    &:hover{
        transition: .4s;
        background-color: var(--white);
        color: var(--primaryColor);
    }
}

.invert{
    background-color: var(--white);
    color: var(--primaryColor);
    font-weight: bold;
    font-size: 22px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.4);

    &:hover{
        transition: .4s;
        background-color: var(--secondColor);
        color: var(--primaryColor);
    }
}

.outline{
    border: 3px solid var(--primaryColor);
    color: var(--white);
    font-weight: bold;
    font-size: 16px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.4);

    &:hover{
        transition: .4s;
        background-color: var(--primaryColor);
    }
}

.long{
    width: 300px;
    height: 60px;
    border-radius: 10px;
}

/* Variations */

.red{
    color: var(--primaryColor);
}

.self-center{
    align-self: center;
}

.mt-2{
    margin-top: 20px;
}

.mt-4{
    margin-top: 40px;
}

.mb-4{
    margin-bottom: 40px;
}

.responsive{
    width: 50%;
}