@media (min-width: 320px){
    .depoimentos-container{
        height: 280px;
        border-radius: 8px;
        margin: 30px 0px 10px 0px;
        background-color: #3a3a3a;
        padding: 20px 20px 10px 20px;
        display: flex;
        flex-direction: column;
        box-shadow: 4px 4px 6px rgba(35, 35, 35, 0.4);
        color: var(--white);
        width: 300px;

        .depoimento-perfil{
            display: flex;
            flex-direction: row;
            margin-bottom: 16px;

            .depoimento-foto{
                width: 75px;
                height: 75px;
                border-radius: 37.5px;
                background-position: center;
                background-size: cover;
            }

            .depoimento-infos{
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                justify-content: space-around;
                
                .depoimento-nome{
                    font-weight: bolder;
                    font-size: 1.2rem;
                }

                .depoimento-curso{
                    font-weight: 300;
                }

                .depoimento-universidade{
                    font-weight: bold;
                    color: #f2f2f2;
                }
            }
        }

        .depoimento-texto{
            font-size: 15px;
            height: 60%;
            margin-bottom: 4px;
            font-style: italic;
        }

        .depoimentos-counter{
            width: 100%;
            height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            
            .counter{
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background-color: rgb(190, 190, 190);
            }

            .active{
                background-color: #F8333C;
            }
        }
    }   

    .card-0{
        display: none;
    }

    .card-2{
        display: none;
    }
}

@media (min-width: 768px){
    .depoimentos-container{
        width: 50%;
    }

    .card-0{
        display: flex;
        filter: opacity(0.2);
        margin-left: -120px;
        .counter{
            display: none;
        }
    }

    .card-2{
        display: flex;
        filter: opacity(0.2);
        margin-right: -120px;

        .counter{
            display: none;
        }
    }
}

/* Fotos */

