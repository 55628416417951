.container-projetos{
    width: 100%;
    height: auto;

    .projetos-content{
        min-height: 100vh;
        background-color: var(--secondColor);
        padding: 80px 10px;
        display: flex;
        flex-direction: column;

        .projetos-title{
            font-weight: bold;
            font-size: 24px;
            color: var(--white);
            margin-top: 20px;
        }
        
        .projetos-call{
            font-weight: bolder;
            font-size: 28px;
            color: var(--white);
        }

        .projetos-text{
            font-size: 16px;
            color: var(--white);
            margin-top: 12px;
        }
    }
}

@media (min-width: 375px) {
    .container-projetos{

        .projetos-content{
            padding: 80px 40px;
        }   
    }
}

@media (min-width: 768px){
    .container-projetos{
        align-items: center;
        .projetos-area{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 20px;
            column-gap: 30px;
        }
    }
}