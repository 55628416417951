.container-contact{
    width: 100%;
    height: auto;

    .contact-content{
        width: 100%;
        padding: 80px 10px;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: white;

        .contact-title{
            color: #FD2B2A;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 8px;
        }

        .contact-text{
            font-size: 16px;
            margin-top: 10px;
        }

        .social-row{
            margin: 10px 0px;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            .social{
                color: var(--primaryColor);
                font-size: 20px;
            }
        }
        
        .mapa{
            width: 300px;
            height: 300px;
            border-radius: 10px;
            margin: 16px 0px;
        }

        .contact-field{
            display: flex;
            flex-direction: column;

            .contact-row{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;
                gap: 10px;

                .contact-icone{
                    font-size: 20px;
                }

                .contact-icone2{
                    font-size: 32px;
                }
            }
        }

        .contact-name{
            font-size: 18px;
            margin-top: 12px;
        }
    }
}

@media (min-width: 375px){
    .container-contact{
        .contact-content{
            padding: 80px 40px;
        }
    }
}

@media (min-width: 425px) {
    
    .container-contact{
        .contact-content{
    
            .mapa{
                width: 345px;
                height: 345px;
            }
    
        }
    }
}

@media (min-width: 768px){
    .contact-content{
        align-items: center;

    }
}