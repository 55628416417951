@media (min-width: 320px){

    .beneficio-container{
        width: 280px;
        height: 220px;
        display: flex;
        flex-direction: column;
        
        .beneficio-icon{
            width: 60px;
            height: 60px;
            font-size: 60px;
        }
    
        .beneficio-title{
            font-weight: bold;
            font-size: 24px;
        }
    
        .beneficio-text{
            font-size: 14px;
            font-weight: 400;
        }
    }

}

@media (min-width: 375px){
    .beneficio-container{
        width: 345px;
        height: 220px;
        display: flex;
        flex-direction: column;
        
        .beneficio-icon{
            width: 75px;
            height: 75px;
            font-size: 75px;
        }
    
        .beneficio-title{
            font-weight: bold;
            font-size: 24px;
        }
    
        .beneficio-text{
            font-size: 16px;
            font-weight: 400;
        }
    }
}