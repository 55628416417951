.avatar-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 74px;    

    .avatar-img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 4px;
        cursor: pointer;

        &:hover{
            transition: .4s;
            transform: translateX(10px) translateY(-10px);
        }
    }

    .avatar-name{
        font-size: bold;
        font-size: 14px;
    }
}