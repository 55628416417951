@media (min-width: 320px){
    
    .menu-container{
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        z-index: 99;
        background-color: #080808;
        
        .menu-logo{
            width: 70%;
            cursor: pointer;
        }

        .menu-padding{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 40px;
            width: 100%;
        }


        .menu-items{
            display: none;
        }

        .menu-items-mobile{
            width: 30px;
            height: 30px;
            color: white;
        }
    }
}

.menu-mobile{
    height: 0;
    width: 100%;
    position: absolute;
    width: 100%;
    top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    background-color: #080808;
    
    .menu-mobile-items{
        flex-direction: column;
        width: 100%;
        height: 70%;
        justify-content: space-around;        

        .menu-item{
            width: 100%;
            color: white;
            font-weight: bolder;
            font-size: 20px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            height: 100%;
        }

        .menu-item:last-of-type{
            border: none;
        }
    }

    .menu-social{
        width: 70%;
        height: 25%;
        border-top: 1px solid white;
        display: none;
        flex-direction: row;
        justify-content: space-around;

        .menu-icon{
            color: white;
            font-size: 24px;
            margin-top: 20px;
        }
    }
}

.animation-extend{
    animation: expansao .4s;
}

.animation-hide{
    animation: supressao .4s;
}

.menu-extend{
    height: 360px;
}

.menu-hide{
    height: 0px;
}

.menu-view{
    display: flex;
}

.menu-noview{
    display: none;
}

/* Animações */

@keyframes expansao{
    from{
        height: 0px;
    }
    to{
        height: 360px;
    }
}

@keyframes supressao {
    from{
        height: 360px;
    }
    to{
        height: 0px;
    }
}