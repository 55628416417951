@media (min-width: 320px) {
    .rodape{
        height: auto;
        width: 100%;
        background-color: #191A1A;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .rodape-img{
            display: flex;
            flex-direction: column;
            align-items: center;

            .social-field{
                border-bottom: 1px solid white;
                padding-bottom: 20px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;            
                margin-top: 24px;
                align-self: center;
    
                .social{
                    width: 20px;
                    height: 20px;
                    color: var(--primaryColor)
                }
            }
    
            .social-img{
                width: 70px;
                margin-top: 40px;
            }

            .logoName{
                width: 70%;
                text-align: center;
                margin-top: 10px;
                font-size: 20px;
                font-weight: bold;
                color: white;
            }
        }

        .rodape-content{
            height: 100%;
            padding: 40px;
    
            .footer-institucional{
                display: flex;
                flex-direction: column;
                height: 200px;
                flex-wrap: wrap;
                width: 100%;
    
                .footer-link{
                    color: white;
                    font-size: 16px;
                    margin-top: 12px;
                }
            
            }
    
            .footer-contato{
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
    
                .footer-contato-line{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 14px;
                    margin-top: 12px;
                    color: white;
                    
                    .icone{
                        font-size: 20px;
                        color: #F8333C;
                    }
    
                    .footer-info{
                        width: 80%;
                        display: flex;
                        flex-wrap: wrap;
                        font-size: 1rem;
                    }

                    .map{
                        font-size: 28px;
                    }
                }
            }
        }

        .footer-title{
            font-weight: bold;
            color: #F8333C;
            font-size: 20px;
        }
    }
        
    .footer-assinatura{
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #181818;
        

        .footer-linktree{
            margin-left: 4px;
            color: white;
            font-weight: bold;
            text-decoration: none;
        }
    }

    .map{
        display: none;
    }
}

@media (min-width: 768px){
    .rodape{
        height: 80vh;

        .rodape-content{
            width: 100%;
            display: flex;
            flex-direction: row;
        
            .footer-institucional{
                margin-left: -110px;
                margin-top: 20px;
            }
        }
    
        .footer-assinatura{
            position: absolute;
            margin-top: 70vh;
            left: 0px;
        }
    }
}

@media (min-width: 1024px){
    .rodape{
        flex-direction: row;
        padding: 0px 80px;
        height: 50vh;   

        .rodape-img{
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .rodape-content{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-left: 40px;
            align-items: flex-start;

            .social-field{
                flex-direction: column;
                margin-left: 20px;
                height: 200px;
            }
        }

        .footer-assinatura{
            position: absolute;
            margin-top: 40vh;
            left: 0px;
        }
    }
}

@media (min-width: 1440px){
    .rodape{
        
        .rodape-content{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            
            .footer-contato{
                margin-right: 80px;
            }

            .map{
                display: block;
                width: 100%;
                height: 90%;
            }   
        }

        .footer-assinatura{
            margin-top: 50vh;
        }
    }
}