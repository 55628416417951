*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

:root{
    --primaryColor: #F90000;
    --secondColor: #080808;
    --redColor: #e41616;
    --white: #FFFFFF;
}

.container-coming{
    width: 100%;
    height: 100vh;
    background-color: #3a3a3a;

    .coming-content{
        display: flex;
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80vh;
        .coming-text{
            font-weight: bolder;
            color: white;
            font-size: 36px;
        }
    }
}